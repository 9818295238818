<template>
  <div class="action-block">
    <div class="common-action dark-gray" @click="showModal">
      <i :class="block.icon"></i> {{  block.name  }}
    </div>
  
    <vuestic-modal
      :isOpen="isShowModal"
      @cancel="isShowModal = false"
      :okShown="false"
      :cancelShown="false"
      :force="true"
      cancelClass="btn btn-danger"
    >
      <span slot="title" class="text-primary font-weight-bold">{{ block.description }}</span>
      <ValidationObserver 
        v-slot="{ handleSubmit }"
        ref="webhookActionForm"
      >
        <form @submit.prevent="handleSubmit(save)" class="webhookActionForm">
          <div class="row">
            <div class="col-12 mb-2">
              <text-input v-model="block.name"></text-input>
            </div>
            <div class="col-md-12 mb-4">
              <label class="input-label">Automation</label>
              <multiselect
                :show-labels="false" 
                v-model="selectedAutomation"
                :options="automations"
                :multiple="false"
                :preserve-search="true"
                placeholder="Select Automation"
                label="name"
                track-by="id"
              >
              </multiselect>
            </div>
            <div class="col-md-12">
              <label class="pb-2">Pass Input Trigger Parameters</label>
              <vuestic-switch
                v-model="block.options.IsIncludeData"
                :offcolor="true"
                style="width: 400px"
              >
                <span slot="trueTitle">On</span>
                <span slot="falseTitle">Off</span>
              </vuestic-switch>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="!selectedAutomation" style="min-width: 80px;">
                <span>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
  </div>
</template>
    
<script>
    export default {
      props: {
        block: {
          type: Object,
          default: null,
        }
      },
    
      data() {
        return {
          loading: false,
          automations: [],
          isShowModal: false,
          selectedAutomation: null,
          automationId: null,
        }
      },
    
      mounted() {
        if (this.block.isNew) {
          this.block.isNew = false
          this.showModal()
        }
        this.automationId = this.$route.params.id
      },

      watch: {
        selectedAutomation(val) {
          if (val) {
            this.block.options.AutomationId = val.id
          }
        }
      },
    
      methods: {
        save() {
          this.isShowModal = false
        },
    
        showModal() {
          this.loadAutomations()
          this.isShowModal = true
        },

        loadAutomations() {
          this.$store.dispatch('automation/all')
            .then((res) => {
              this.automations = res.filter(item => item.active && item.id != this.automationId)

              if (this.block.options.AutomationId) {
                this.selectedAutomation = this.automations.find(a => a.id === this.block.options.AutomationId)
              }
            })
            .catch(() => {
            })
        },
      }
    }
  </script>
    
<style lang="scss" scoped>
</style>